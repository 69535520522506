import { Pagination } from '@/api/pagination';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VeiculoAPI } from '@/api/veiculo.api';
import { PageVeiculoInput, Veiculo } from '@/models/veiculo';

const api = new VeiculoAPI();

const page = (input: PageVeiculoInput): Observable<Pagination<Veiculo>> => {
  return api.page(input).pipe(map((data: any) => {
    const { pageVehicles: { items, totalItems } } = data;
    return { items, ...totalItems }
  }));
}


export default {
  page,

}
