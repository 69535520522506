<template>
  <section>
    <PaymentForm
      :paymentProductName="paymentProductName"
      :paymentProductValue="paymentProductValue"
      :paymentProductOwnerIdentification="paymentProductOwnerIdentification"
      @doPayment="doPayment"
    />
    <section class="loading" v-if="form.isLoading">
      <v-progress-circular indeterminate color="success"></v-progress-circular>
      <span>Enviando informações...</span>
    </section>
  </section>
</template>

<script>
import PaymentForm from "@/components/Payment/PaymentForm";
import { mapState, mapActions } from "vuex";
export default {
  name: "RenovacaoRegistroVeiculoPagamentoForm",
  components: {
    PaymentForm
  },
  methods: {
    doPayment(value) {
      this.doPayment(value);
    },
    ...mapActions("exclusaoVeiculo", { doPayment: "doPayment" })
  },
  computed: {
    ...mapState("exclusaoVeiculo", ["form"]),
    paymentProductName: function() {
      return this.form.data ? this.form.data.typeSolicitation.name : "";
    },
    paymentProductValue: function() {
      return this.form.data ? Number(this.form.data.typeSolicitation.value) : 0;
    },
    paymentProductOwnerIdentification: function() {
      return this.form.data ? this.form.data.company.cnpj : "";
    }
  }
};
</script>
