<template>
  <article class="cart-detail">
    <section class="cart-detail-item">
      <h3 class="text-left">
        <em>{{ paymentDetailName }}</em>
      </h3>
      <h4 class="text-right">
        <em>{{ paymentDetailValue | currency }}</em>
      </h4>
    </section>
  </article>
</template>

<script>
export default {
  name: "PaymentDetalhe",
  props: {
    paymentDetailName: {
      type: String,
      required: true
    },
    paymentDetailValue: {
      type: Number,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.cart-detail {
  &-item {
    display: flex;
    padding: 10px 0px;
    .text-left {
      width: 80%;
      margin: 0px;
      font-size: 15px !important;
      em {
        font-style: normal;
        display: block;
        font-weight: 500;
      }
      span {
        font-size: 13px !important;
      }
    }
    .text-right {
      width: 20%;
      margin: 0px;
      font-size: 15px !important;
      align-content: space-around;
      em {
        font-style: normal;
        font-size: 15px !important;
        display: block;
        font-weight: 500;
      }
    }
  }
}
</style>
