import { TipoPedidoAPI } from '@/api/tipo-pedido.api';
import { Pagination } from '@/api/pagination';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TipoPedido, PageTipoPedidoInput } from "@/models/tipo-pedido"


const api = new TipoPedidoAPI();

const page = (input: PageTipoPedidoInput): Observable<Pagination<TipoPedido>> => {
  return api.page(input).pipe(map((data: any) => {
    const { pageTypeSolicitations: { items, totalItems } } = data;
    return { items, ...totalItems }
  }));
}


export default {
  page,
}
