<template>
  <form class="app-form mt-4" id="form-checkout">
    <v-row>
      <v-col :lg="12" :md="12" v-show="false">
        <label for="form-checkout__cardholderEmail">Email *</label>
        <input
          type="email"
          name="cardholderEmail"
          placeholder="Digite um email válido"
          required
          id="form-checkout__cardholderEmail"
          :value="defaultEmailPayment"
        />
      </v-col>
      <v-col :lg="5" :md="5" v-show="false">
        <label for="form-checkout__identificationType"
          >Tipo de Documento *</label
        >
        <select
          outlined
          name="identificationType"
          id="form-checkout__identificationType"
          placeholder="Tipo de Documento"
          required
          :value="'CNPJ'"
        ></select>
      </v-col>
      <v-col :lg="7" :md="7" v-show="false">
        <label for="form-checkout__identificationNumber"
          >Número do Documento *</label
        >
        <input
          type="text"
          name="identificationNumber"
          id="form-checkout__identificationNumber"
          required
          v-mask="['###########', '##############']"
          :value="paymentCartaoIndendificationNumber"
        />
      </v-col>
      <v-col :lg="6" :md="6">
        <label for="form-checkout__cardNumber">Número Cartão *</label>
        <input
          placeholder="Digite o Número do Cartão"
          type="text"
          name="cardNumber"
          id="form-checkout__cardNumber"
          required
          v-mask="'################'"
        />
      </v-col>
      <v-col :lg="2" :md="2">
        <label for="form-checkout__cardExpirationMonth">Mês *</label>
        <input
          type="text"
          placeholder="DD"
          name="cardExpirationMonth"
          id="form-checkout__cardExpirationMonth"
          required
          v-mask="'##'"
        />
      </v-col>
      <v-col :lg="2" :md="2">
        <label for="form-checkout__cardExpirationYear">Ano *</label>
        <input
          type="text"
          placeholder="AA"
          name="cardExpirationYear"
          id="form-checkout__cardExpirationYear"
          required
          v-mask="'##'"
        />
      </v-col>
      <v-col :lg="2" :md="2">
        <label for="form-checkout__securityCode">CVV *</label>
        <input
          placeholder="CVV"
          type="text"
          name="securityCode"
          id="form-checkout__securityCode"
          required
          v-mask="'###'"
        />
      </v-col>
      <v-col :lg="12" :md="12">
        <label for="form-checkout__cardholderName">Nome no cartão *</label>
        <input
          type="text"
          name="cardholderName"
          placeholder="Digite o nome escrito no cartão"
          required
          id="form-checkout__cardholderName"
        />
      </v-col>
      <v-col :lg="12" :md="12" v-show="false">
        <label for="form-checkout__issuer">Banco *</label>
        <select
          outlined
          name="issuer"
          id="form-checkout__issuer"
          required
        ></select>
      </v-col>
      <v-col :lg="12" :md="12" class="mb-5">
        <label for="form-checkout__installments">Parcelas *</label>
        <select
          outlined
          name="installments"
          id="form-checkout__installments"
          label="Parcelas"
          placeholder="Selecione parcelas"
          required
        ></select>
      </v-col>
      <v-col :lg="12" :md="12" class="mb-5">
        <v-btn
          depressed
          color="success"
          id="form-checkout__submit"
          type="submit"
          block
          >Finalizar Pagamento</v-btn
        >
      </v-col>
    </v-row>
  </form>
</template>

<script>
import {
  MERCADO_PAGO_PUBLIC_KEY,
  DEFAULT_EMAIL_PAYMENT
} from "@/commons/config";

export default {
  name: "PaymentCartao",
  props: {
    paymentCartaoIndendificationNumber: {
      type: String,
      required: true,
      default: ""
    },
    paymentCartaoIndendificationValue: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data: () => ({
    isLoading: true,
    mp: null,
    cardForm: null,
    defaultEmailPayment: DEFAULT_EMAIL_PAYMENT
  }),
  methods: {
    mountForm() {
      this.mp = new MercadoPago(MERCADO_PAGO_PUBLIC_KEY);
      this.cardForm = this.mp.cardForm({
        amount: String(this.paymentCartaoIndendificationValue),
        autoMount: true,
        processingMode: "aggregator",
        form: {
          id: "form-checkout",
          cardholderName: {
            id: "form-checkout__cardholderName",
            placeholder: "Nome escrito no cartão"
          },
          cardholderEmail: {
            id: "form-checkout__cardholderEmail",
            placeholder: "Email"
          },
          cardNumber: {
            id: "form-checkout__cardNumber",
            placeholder: "Número escrito no cartão"
          },
          cardExpirationMonth: {
            id: "form-checkout__cardExpirationMonth",
            placeholder: "MM"
          },
          cardExpirationYear: {
            id: "form-checkout__cardExpirationYear",
            placeholder: "AA"
          },
          securityCode: {
            id: "form-checkout__securityCode",
            placeholder: "CVV"
          },
          installments: {
            id: "form-checkout__installments",
            placeholder: "Total de Parcelas"
          },
          identificationType: {
            id: "form-checkout__identificationType",
            placeholder: "Tipo de documento"
          },
          identificationNumber: {
            id: "form-checkout__identificationNumber",
            placeholder: "Número do documento"
          },
          issuer: {
            id: "form-checkout__issuer"
            //placeholder: "Banco"
          }
        },
        callbacks: {
          onFormMounted: error => {
            if (error) return;
            //console.log("Montado!");
          },
          onFormUnmounted: error => {
            if (error) return;
            //console.log("Desmontado!");
          },
          onIdentificationTypesReceived: (error, identificationTypes) => {
            if (error) return;
          },
          onPaymentMethodsReceived: (error, paymentMethods) => {
            if (error) return;
          },
          onIssuersReceived: (error, issuers) => {
            if (error) return;
          },
          onInstallmentsReceived: (error, installments) => {
            if (error) return;
          },
          onCardTokenReceived: (error, token) => {
            if (error) return;
          },
          onSubmit: async event => {
            event.preventDefault();
            await this.cardForm.createCardToken();
            const cardData = this.cardForm.getCardFormData();
            this.createPayment(cardData);
          },
          onFetching: resource => {
            this.isLoading = true;
            return () => {
              this.isLoading = false;
            };
          }
        }
      });
    },
    createPayment(value) {
      this.$emit("doPayment", value);
    }
  },
  mounted() {
    this.mountForm();
  }
};
</script>

<style lang="scss" scoped>
.v-text-field__details {
  padding-left: 0px !important;
}
select,
input {
  border: solid 1px #9e9e9e;
  border-radius: 4px;
  width: 100%;
  padding: 15px 10px;
}
.title-produto {
  margin: 0px;
  font-weight: 400 !important;
  font-size: 15px !important;
}

.valor-produto {
  font-weight: 500 !important;
}

.isLoading {
  display: none;
  &--show {
    display: table;
  }
}
</style>
