<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex lg12 sm12 xs12>
        <v-card class="app-card">
          <v-card-title class="app-card-title"
            >Exclusão de Veículo</v-card-title
          >
          <v-card-text class="app-card-content">
            <v-stepper
              outlined
              alt-labels
              class="app-stepper"
              v-model="form.currentStep"
            >
              <v-stepper-header class="app-stepper-header">
                <v-stepper-step
                  step="1"
                  class="app-stepper-header__step"
                  color="success"
                  :complete="form.currentStep > 1"
                  >Veículo</v-stepper-step
                >
                <v-spacer></v-spacer>
                <v-stepper-step
                  step="2"
                  class="app-stepper-header__step"
                  color="success"
                  :complete="form.currentStep > 2"
                  :editable="form.currentStep === 3"
                  >Documentos</v-stepper-step
                >
                <v-spacer></v-spacer>
                <v-stepper-step
                  step="3"
                  class="app-stepper-header__step"
                  color="success"
                  :complete="form.currentStep > 3"
                  >Pagamento</v-stepper-step
                >
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <VeiculoForm />
                </v-stepper-content>
                <v-stepper-content step="2">
                  <DocumentosForm />
                </v-stepper-content>
                <v-stepper-content step="3">
                  <PagamentoForm />
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import VeiculoForm from "./VeiculoForm";
import DocumentosForm from "./DocumentosForm";
import PagamentoForm from "./PagamentoForm";
import GlobalStore from "@/store/modules/global";

export default {
  name: "InclusaoVeiculo",
  components: {
    VeiculoForm,
    DocumentosForm,
    PagamentoForm
  },
  methods: {
    ...mapActions("exclusaoVeiculo", {
      clearForm: "clearForm"
    })
  },
  computed: {
    ...mapState("exclusaoVeiculo", ["form"])
  },
  destroyed() {
    this.clearForm();
  }
};
</script>

<style lang="scss" scoped>
.logo {
  width: 275px;
  margin: 0px auto 20px auto;
  display: table;
}
</style>
