<template>
  <v-row>
    <v-col :lg="5" :md="5">
      <v-card class="app-card app-card--custom">
        <v-card-title class="app-card-title mb-0">
          <h4 class="text">
            <v-icon>shopping_basket</v-icon>
            <span>Detalhes</span>
          </h4>
        </v-card-title>
        <v-card-text class="app-card-content mt-3">
          <PaymentDetalhe
            :paymentDetailName="paymentProductName"
            :paymentDetailValue="paymentProductValue"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col :lg="7" :md="7">
      <v-expansion-panels accordion class="app-expansion">
        <v-expansion-panel class="app-expansion-panel">
          <v-expansion-panel-header class="app-expansion-panel-header">
            <h4 class="text">
              <v-icon>credit_card</v-icon>
              <span>Pagamento Cartão</span>
            </h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <PaymentCartao
              :paymentCartaoIndendificationNumber="
                paymentProductOwnerIdentification
              "
              :paymentCartaoIndendificationValue="paymentProductValue"
              @doPayment="doPayment"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="app-expansion-panel">
          <v-expansion-panel-header class="app-expansion-panel-header">
            <h4 class="text">
              <v-icon>request_quote</v-icon>
              <span>Pagamento Boleto</span>
            </h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <PaymentBoleto
              :paymentCartaoIndendificationNumber="
                paymentProductOwnerIdentification
              "
              :paymentCartaoIndendificationValue="paymentProductValue"
              @doPayment="doPayment"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="app-expansion-panel">
          <v-expansion-panel-header class="app-expansion-panel-header">
            <h4 class="text">
              <img src="../../assets/images/pix.svg" alt="img-pix" width="22px" height="22px">
              <span>Pagamento Pix</span>
            </h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <PaymentPix :paymentCartaoIndendificationNumber="
                paymentProductOwnerIdentification
              "
              :paymentCartaoIndendificationValue="paymentProductValue" @doPayment="doPayment"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import PaymentDetalhe from "@/components/Payment/PaymentDetalhe";
import PaymentBoleto from "@/components/Payment/PaymentBoleto";
import PaymentCartao from "@/components/Payment/PaymentCartao";
import PaymentPix from "@/components/Payment/PaymentPix";

export default {
  name: "PaymentForm",
  props: {
    paymentProductName: {
      type: String,
      required: true,
      default: ""
    },
    paymentProductValue: {
      type: Number,
      required: true,
      default: 0
    },
    paymentProductOwnerIdentification: {
      type: String,
      required: true,
      default: ""
    }
  },
  components: {
    PaymentDetalhe,
    PaymentBoleto,
    PaymentCartao,
    PaymentPix
  },
  methods: {
    doPayment(value) {
      this.$emit("doPayment", value);
    },
  }
};
</script>
