import { print } from "graphql";
import { BaseAPI } from '@/api/base.api'
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'
import { Pagination } from './pagination';
import { Veiculo, PageVeiculoInput } from '@/models/veiculo';

const page  = require('@/query/veiculo/Page.graphql')

export class VeiculoAPI extends BaseAPI<Veiculo> {
  constructor() {
    super();
    this.backUrl = `${this.backUrl}/api/vechicles`;
  }


  public page(
    input: PageVeiculoInput
  ): AxiosObservable<Pagination<Veiculo>> {
    return this.axios
      .post(`${this.graphqlUrl}`, {
        query: print(page),
        variables: { ...input }
      })
  }

}
